<template>
  <textarea
    class="textarea"
    :placeholder="item.placeholder"
    v-model="item.model"
  ></textarea>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
    },
  },
};
</script>

<style scoped lang="scss">
.textarea {
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 7px 13px;
  width: 100%;
  height: 86px;
  resize: none;
  @media (max-width: 767px) {
    padding: 5px 10px;
    height: 65px;
  }
  &::placeholder {
    color: #6c757d;
  }
}
</style>

